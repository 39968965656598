<template>
    <div class="main-container">
      <h5 class="pageTitle">기수 등록</h5>
      
      <div class="form-container">
        <div class="form-group">
          <label class="form-label" for="batch_name">기수 명</label>
          <input v-model="form.batch_name" type="text" class="input-box" placeholder="기수 명을 입력하세요"  @blur="checkBatchName"/>
          <p v-if="batchNameExists" class="error-text">이미 존재하는 기수 명입니다.</p>
        </div>
  
        <div class="form-group">
          <label for="selected_users">이름</label>
          <textarea v-model="selectedNames" class="user-list" readonly></textarea>
        </div>
  
        <div class="button-container">
          <button class="button-register" @click="registerBatch" :disabled="batchNameExists" :class="{ 'disabled-button': batchNameExists }">등록</button>
          <button class="button-list" @click="goToList">목록</button>
        </div>
      </div>
  
       <!-- 사용자 목록 -->
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>이름</th>
            <th>전화번호</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedUsers.length === 0">
            <td colspan="3">등록 가능한 사용자가 없습니다.</td>
          </tr>
          <tr v-for="(user, index) in paginatedUsers" :key="user.uuid" class="clickable-row" @click="toggleUserSelection(user)">
            <td>{{ index + 1 + (currentPage - 1) * perPage}}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.phone }}</td>
          </tr>
        </tbody>
      </table>

       <!-- 페이지네이션 -->
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1 ">Prev</button>
        <span>Page {{ currentPage }} of {{ totalPages  }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === totalPages ">Next</button>
      </div>

    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
  
    data() {
      return {
        form: {
          batch_name: "",
          users: []
        },
        users: [],
        selectedUsers: [],
        currentPage: 1,
        perPage: 10,
        batchNameExists: false,
      };
    },
    computed: {
      selectedNames() {
        return this.selectedUsers.map(user => user.name).join(", ");
      },
      totalPages() {
        return Math.ceil(this.users.length / this.perPage);
      },
      paginatedUsers() {
        if (!this.users || this.users.length === 0) return [];
        const start = (this.currentPage - 1) * this.perPage;
        const end = start + this.perPage;        
        return this.users.slice(start,end);
      },
     
    },
    created() {
      this.fetchUsers();
    },
   
    methods: {
      async fetchUsers() {
        try {
          const token = this.getToken();
          const response = await axios.get("/users/no-batch-list", {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.users = response.data;
        } catch (error) {
          console.error("사용자 목록 불러오기 실패:", error);
        }
    },
      toggleUserSelection(user) {
        const index = this.selectedUsers.findIndex(u => u.uuid === user.uuid);
        if (index === -1) {
          this.selectedUsers.push(user);
        } else {
          this.selectedUsers.splice(index, 1);
        }
      },
      async checkBatchName() {
        console.log("CheckBatch start!");
        if (!this.form.batch_name.trim()) return;
        try {
          console.log("Checking batch name:", this.form.batch_name); // Debug log
          const token = this.getToken();
          const response = await axios.get(`/users/checkDupBatch?name=${this.form.batch_name}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.batchNameExists = response.data.exists;
          this.$forceUpdate();
          console.log("Batch name exists:", this.batchNameExists); // Debug log
        } catch (error) {
          console.error("기수명 중복 확인 실패:", error);
        }
      },
      async registerBatch() {
        if (!this.form.batch_name || this.selectedUsers.length === 0) {
          Swal.fire("오류", "기수 명과 회원을 선택해야 합니다.", "error");
          return;
        }
        if (this.batchNameExists) {
          Swal.fire("오류", "이미 존재하는 기수 명입니다.", "error");
          return;
        }
        try {
          const token = this.getToken();
          const formData = {
            batch_name: this.form.batch_name,
            user_ids: this.selectedUsers.map(user => user.uuid),
          };
          await axios.post("/users/batch-create", formData, {
            headers: { Authorization: `Bearer ${token}` },
          });
          Swal.fire("성공", "기수가 등록되었습니다.", "success");
          this.goToList();
        } catch (error) {
          Swal.fire("오류", "기수 등록 실패.", "error");
          console.error("기수 등록 오류:", error);
        }
      },
      goToList() {
        this.$router.push("/batch-read");
      },
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData ? tokenData.access_token : "";
      },
      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      },
      nextPage() {
        if (this.currentPage < this.totalPages) this.currentPage++;
      },
    },
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 300px;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
 /* .input-group {
    display: flex;
    align-items: center;
  }*/
  .input-box {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 300px; /* 기수명 입력 상자 길이 조정 */
    width: 100%;
   }
  
  .user-list-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .user-list {
    flex: 1;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 4px;
    min-height: 200px;
    max-width: 300px; /* 사용자 목록 크기 고정 */
    width: 100%;
  }
  .btn-select {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap; /* 버튼 크기 고정 */
    align-self: flex-start; /* 버튼을 상단 정렬 */
  }
  .btn-select:hover {
    background-color: #0056b3;
  }
  /* .user-selection-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
  } */
  .button-container {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
  }
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    margin-right: 10px;
  }
  .disabled-button {
    background-color: #ccc !important;
    cursor: not-allowed !important;
    color: #666 !important;
  }
  /* .button-cancel {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
  } */
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .user-table-container {
    margin-top: 20px;
    width: 100%;
  }

   
    .selected {
    background-color: #e6f2ff;
    }

    table {
    width: 80%;
    border-collapse: collapse;
    /* margin: auto; */
    margin-top: 40px;
    text-align: center;
    }
    th, td {
      border: 1px solid #cbc8c8;
      padding: 8px;
      text-align: center;
    }
    th {
      background-color: #e3e4eb;
      height: 55px;
    }
    tr {
      height: 50px;
      cursor: pointer;
    }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  .error-text {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  </style>
  